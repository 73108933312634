import request from './request'
// TODO 获取验证码
function getVerifyCode(data) {
    return request('get','/qxapi/edu/customer/verifycode', data)
}
// TODO 登录
function login(data) {
    return request('get','/qxapi/edu/customer/login', data)
}
// TODO 志愿者登录
function loginByVolunteer(data) {
    return request('get','/qxapi/edu/customer/loginbyvolunteer', data)
}
// TODO 幸福夜校班登录
function loginByAdult(data) {
    return request('get','/qxapi/edu/customer/loginbyadult', data)
}

// TODO 根据ID获取
function loginById(data) {
    return request('get','/qxapi/edu/customer/loginbyid/' + data)
}

// TODO 根据OpenID登录
function loginByOfficalOpenId(data) {
    return request('get','/qxapi/edu/customer/loginbyofficalopenid', data)
}

// TODO 检测是否有空字段未填写
function checkHasBlankField(custId) {
    return request('get','/qxapi/edu/customer/checkhasblanfield/' + custId)
}

// TODO 判断是否超过3次认证
function overCheckIDQty(custId) {
    return request('get','/qxapi/edu/customer/overcheckidqty/' + custId)
}

// TODO 补完信息
function completeMsg(data) {
    return request('post','/qxapi/edu/customer/completemsg', data)
}

// TODO 补完教师资料
function completeJSZLMsg(data) {
    return request('post','/qxapi/edu/customer/completejszlmsg', data)
}

// TODO 补完志愿者信息
function completeVolunteerMsg(data) {
    return request('post','/qxapi/edu/customer/completevolunteermsg', data)
}


// TODO 教师资料明细
function getJXZLDetails(data) {
    return request('get','/qxapi/edu/customer/jszldetails', data)
}


// TODO 检测是否退休
function checkIsRetire(data) {
    return request('get','/qxapi/edu/customer/checkisretire', data)
}

// TODO 上传图片
function uploadPhoto(data) {
    return request('post', '/qxapi/edu/customer/uploadphoto', data)
}

// TODO 绑定微信
function bindOfficalOpenId(data) {
    return request('post','/qxapi/edu/customer/bindofficalopenid', data)
}

// TODO 修改密码
function modifyPassword(data) {
    return request('post', '/qxapi/edu/customer/modifypassword', data)
}

// TODO 获取验证码
function getPhoneCode(data) {
    return request('get', '/qxapi/edu/customer/getphonecode', data)
}

// TODO 注册用户
function register(data) {
    return request('post', '/qxapi/edu/customer/register', data)
}

// TODO 注册志愿者
function registerVolunteer(data) {
    return request('post', '/qxapi/edu/customer/registervolunteer', data)
}

// TODO 注册幸福夜校班
function registerAdult(data) {
    return request('post', '/qxapi/edu/customer/registeradult', data)
}

// TODO 修改密码
function editPwd(data) {
    return request('post', '/qxapi/edu/customer/editpwd', data)
}

// TODO 修改密码
function veditPwd(data) {
    return request('post', '/qxapi/edu/customer/veditpwd', data)
}

export default {
    getVerifyCode,
    login,
    loginByVolunteer,
    loginByAdult,
    checkHasBlankField,
    overCheckIDQty,
    completeMsg,
    completeJSZLMsg,
    completeVolunteerMsg,
    getJXZLDetails,
    checkIsRetire,
    uploadPhoto,
    loginByOfficalOpenId,
    bindOfficalOpenId,
    loginById,
    modifyPassword,
    getPhoneCode,
    register,
    registerVolunteer,
    editPwd,
    veditPwd,
    registerAdult
}
