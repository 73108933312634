<template>
    <div class="edu-frame">
        <edu-nav-bar title="上传照片" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <div class="edu-group" style="margin: 20px auto;">
                <div class="step-tips">请选择照片进行上传</div>
<!--                <div class="step-tips-detail" style="color: black;">请填写您的基本资料（以下带<span
                        style="color: red;">*</span>的是<span style="color: red;">必填项</span>）
                </div>-->
                <div class="note-content" v-if="isReUploadAvatar==1" style="border-top: 0px;margin-top: 0px;">
                  <div>您的刷脸入校照片导入失败，为了不影响通行，</div>
                  <div>请按以下要求重新上传照片：</div>
                </div>
                <div class="photo-frame">
                    <img v-if="cust.imgUrl != null && cust.imgUrl != ''" class="photo"
                         :src="cust.imgUrl.indexOf('data:image') > -1 ? cust.imgUrl : resourceUrl+cust.imgUrl +'?date='+ (new Date().valueOf())"
                         style="border: 1px solid lightgrey;">
                    <img v-else class="photo" src="../../statics/images/photo.png">
<!--                    <div class="photo-tips">*要求相片为白底证件照</div>-->
                    <van-button style="margin-top: 10px;" square type="default" @click="chooseImage">选择</van-button>
                    <input style="display: none;" id="browse" ref="browse" type="file"
                           accept="image/jpeg,image/jpg,image/png" @change="previewFiles($event)">
                </div>
                <!-- 说明 -->
                <div class="note-content">
                  <div class="note-title" style="color: red;">照片要求:</div>
                  <div style="margin-top: 10px;"><span style="color: black;font-weight: bold;">背景：</span>选择白底、背景无杂物的近照，即时拍摄的可请亲友用学员本人手机、学员贴靠白墙拍摄。</div>
                  <div style="margin-top: 10px;line-height: 25px;"><span style="color: black;font-weight: bold;">人脸：</span>
                    <div>(1) 人脸对焦清晰、色彩真实</div>
                    <div>(2) 请勿上传美颜后期过度或对照翻拍的照片</div>
                    <div>(3) 不戴墨镜、有色或粗框等遮挡眼睛的眼镜，镜片无反光</div>
                    <div>(4) 不戴帽子、头巾等遮挡头部的饰品</div>
                    <div>(5)照片建议稍作裁切，保留颈部以上人脸影像</div>
                  </div>
                  <br>
                  <div><span style="color: black;font-weight: bold;">示范照片：</span></div>
                  <div style="display: flex;justify-content: center;width: 130px;margin: 0 auto;">
                    <img src="../../statics/images/avatardemo.jpg" style="width: 100%;">
                  </div>
                </div>
                <!-- <div class="note-content">
                    <div class="note-title">01重要性</div>
                    <div class="note-title-eng">&nbsp;&nbsp;&nbsp;&nbsp;importance</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;人脸识别注册照片是用于人脸比对的基准数据，因此注册照片是否清晰将对识别结果有较大影响。对于注册照片的大小、格式、照片中人脸的大小、角度、面部饰物等都有如下规范，建议使用者按照如下要求提供注册照片。</div>
                    <br>
                    <div class="note-title">02照片要求</div>
                    <div class="note-title-eng">&nbsp;&nbsp;&nbsp;&nbsp;photo request</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;<label style="font-weight:bold;">背景：</label>照片背景色为<label
                            style="color:red;font-weight:bold;">均匀单色，不能有杂物。</label></div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;<label style="font-weight:bold;">人脸：</label></div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;（1）人脸<label style="color:red;font-weight:bold;">对焦准确、层次清晰、色彩真实</label>，不得对人脸特征（伤疤、痣等）进行技术处理。
                    </div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;（2）不得戴有色眼镜，不宜戴粗框眼镜，眼镜不得遮挡眼睛，镜片无反光。</div>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;（3）不得使用头部遮盖物（帽子等）。</div>
                    <br>
                    <div>示范照片：</div>
                    <div style="display: flex;justify-content: center">
                        <img src="../../statics/images/headerdemo.png">
                    </div>
                </div> -->
                <!-- 说明 -->
            </div>
        </div>
        <div class="bottom-frame">
            <van-button :loading="loading" square block type="primary" @click="uploadPhoto">保存</van-button>
        </div>
    </div>
</template>
<script>
    import {Button} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import Tools from "@/api/Tools";
    import CustomerApi from "@/api/CustomerApi";

    export default {
        components: {
            EduNavBar,
            VanButton: Button,
        },
        data() {
            return {
                show: false,
                cust: Tools.getCurCust(),
                loading: false,
                resourceUrl: 'http://' + document.domain,
                isReUploadAvatar: null
            }
        },
        methods: {
            previewFiles() {
                var file = document.getElementById("browse").files[0];
                // console.log(file)
                var self = this
                Tools.uploadFileByBase64(file, (e) => {
                    self.cust.imgUrl = e
                    self.$toast('请注意提交保存照片。')
                })
            },
            chooseImage() {
                this.$refs.browse.click()
            },
            uploadPhoto() {
                if(this.loading) {
                  return
                }
                // console.log(this.cust.imgUrl)
                this.loading = true
                var condition = { custId: this.cust.custId, imgUrl: this.cust.imgUrl }
                CustomerApi.uploadPhoto(condition).then(response => {
                    this.loading = false
                    if (response.code == 100) {
                        this.$dialog.alert({message: '上传成功。'})
                        CustomerApi.loginById(this.cust.custId).then(res => {
                            Tools.setCurCust(res.res)
                            Tools.setToken(res.res.token)
                            this.cust = res.res
                        })
                    }
                }).catch(() => { this.loading = false })
            }
        },
        mounted() {
          this.isReUploadAvatar = sessionStorage.getItem('isreuploadavatar')
          sessionStorage.removeItem('isreuploadavatar')
        }
    }
</script>
<style scoped>
    .photo-frame {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 15px auto;
    }

    .photo {
        width: 140px;
    }

    .photo-tips {
        color: red;
        font-size: 14px;
        margin-top: 5px;
    }

    .note-content {
        width: 85%;
        border-top: 1px solid lightgray;
        padding-top: 15px;
        margin: 20px auto;
        font-size: 14px;
    }

    .note-title {
        font-size: 20px;
        color: rgb(95, 192, 248);
        font-weight: bold;
    }

    .note-title-eng {
        color: rgb(95, 192, 248);
    }
</style>
